import { int } from "./primitives"

export interface IGreenskyEvent {
    id: string,
    applicationId: string,
    eventType: string,
    value: string,
    timestamp: string,
    merchantId: string,
    eventId: string | null
}

export enum GreenskyEventType {
    APPLICATION_STATUS_CHANGED = "APPLICATION_STATUS_CHANGED",
    ACTIVATION_STATUS_CHANGED = "ACTIVATION_STATUS_CHANGED",
    TRANSACT_STATUS_CHANGED = "TRANSACT_STATUS_CHANGED",
    TXN_REQUEST_STATUS_CHANGED = "TXN_REQUEST_STATUS_CHANGED",
}

export enum GreenskyApplicationStatus {
    APPROVED = 'Approved',
    AUTHORIZED = 'Authorized',
    DECLINED = 'Declined',
    UNDER_REVIEW = 'Under Review'
}

export enum GreenskyActivationStatus {
    ACTIVATED = 'ACTIVATED',
    NOT_ACTIVATED = 'NOT ACTIVATED'
}

export enum GreenskyTransactStatus {
    CLOSED = 'CLOSED',
    OPEN = 'OPEN',
    BLOCKED = 'BLOCKED'
}

export enum GreenskyTxnRequestStatus {
    ACCEPTED = 'ACCEPTED',
    REJECTED = 'REJECTED',
    CANCELLED = 'CANCELLED',
    EXPIRED = 'EXPIRED',
    POSTED = 'POSTED'
}

export interface IGreenSkyApplication {
    applicationId: string,
    lastName: string,
    firstName: string,
    phoneNumber: string,
    cellNumber: string,
    officePhoneNumber: string,
    coApplicantLastName: string,
    coApplicantFirstName: string,
    coApplicantPhoneNumber: string,
    coApplicantCellNumber: string,
    decision: GreenskyApplicationStatus,
    planNumber: string,
    planDescription: string,
    openToBuy: string,
    creditLimitOffered: string,
    creditLimit: string,
    currentBalance: string,
    applicationDate: string,
    purchaseWindowExpirationDate: string,
    referenceNumber: string,
    city: string,
    zipCode: string,
    applicationToken: string,
    creditCardUID: string,
    authorizationStatus: string,
    activationStatus: string,
    cardStatus: string,
    businessProgram: string,
    authApprovedAmount: string,
    stageFundingThreshold: string,
    mcl: string,
    fundingType: string
}

export interface IGreenskyPlan {
    merchantId: string,
    planId: string,
    activationStatus: string,
    merchantFee: int
}